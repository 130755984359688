import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Certification from "../components/Certification"

const Certs = () => (
  <Layout pageTitle="Certifications">
    <SEO title="Certifications" />
    <h1>Certifications</h1>
    <br></br>
    <Certification courseTitle="Continuous Integration / Continuous Delivery" description=" (University of Helsinky - 2024):" title="certificate-cicd-edited" />
    <Certification courseTitle="Relational databases" description=" (University of Helsinky - 2024):" title="certificate-psql-edited" />
    <Certification courseTitle="TypeScript" description=" (University of Helsinky - 2023):" title="certificate-typescript-edited" />
    <Certification courseTitle="Docker" description=" (University of Helsinky - 2022):" title="certificate-containers-edited" />
    <Certification courseTitle="React Native" description=" (University of Helsinky - 2020):" title="certificate-reactnative-edited" />
    <Certification courseTitle="Full Stack Web Developer" description=" (University of Helsinky - 2019):" title="certificate-fullstackopen-edited" />
    <Certification courseTitle="Web Applications Developer" description=" (UNED - 2018):" title="certificado-uned-edited" />
  </Layout>
)

export default Certs