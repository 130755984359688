import React from "react"
import CertificationImage from './certificationImage'
import './project.css'

const Certification = ( props ) => (
  <div className={ `cert-container` }>
    <p style={{ maxWidth: `1000px`, margin: `50px auto 15px auto`, textAlign: `center` }}>
      <b>{props.courseTitle}</b> {props.description}
    </p>
    <div className={ `shadowed-image` } style={{maxWidth: 800, margin: 'auto'}}>
      <CertificationImage alt={props.title} filename={props.title} />
    </div>
  </div>  
)

export default Certification